import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
  Route,
} from 'react-router-dom';
import User from '../../../services/User';

const Private = ({ component: Component, ...rest }) => {
  const session = User.getSession();

  return (
    <Route
      {...rest}
      render={props =>
        session ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

Private.propTypes = {
  component: PropTypes.instanceOf(Function),
  location: PropTypes.object,
};

export default Private;
