import { firestore } from '../helpers/firebase';
import { generateGUID } from '../helpers/functions';

class Coupon {
  static async create(user) {
    const key = generateGUID();
    return firestore.collection('coupons').doc(key).set({
      user,
      creationDate: new Date(),
      type: 'Corinto',
      isRedeemed: false,
      redeemedDate: '',
    });
  }

  static get(id) {
    return firestore.collection('coupons').where('user', '==', id).get();
  }
}

export default Coupon;