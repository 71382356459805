import {
  GET_TRIP_INFORMATION,
  DELIVERED_TRIP_INFORMATION,
  APPLY_TO_TRIP,
  CANCEL_APPLY_TO_TRIP,
  DONE_TRIP_UPDATE,
  HIDE_NOTIFICATION,
  UPDATED_TRIP,
  UPDATE_REVIEW,
} from '../actions';

export default function trip(state = {
  isFetching: false,
  user: {
    uid: '',
    name: {
      firstName: '',
      lastName: '',
    },
    preferences: {
      eat: '',
      animals: '',
      music: '',
      kids: '',
      talk: '',
      smoke: '',
    },
    vehicles: {

    },
    isValid: 0,
  },
  trip: {
    car: '',
    cost: 0,
    date: '',
    destination: 'Destino',
    details: 'Detalles',
    origin: 'Origen',
    seats: 0,
    userId: '',
    status: 0,
    passengers: [],
  },
  notification: false,
  message: '',
}, action) {
  switch (action.type) {
    case GET_TRIP_INFORMATION:
      return {
        ...state,
        isFetching: true,
      };
    case DELIVERED_TRIP_INFORMATION:
      return {
        isFetching: false,
        user: action.user,
        trip: action.trip,
      };
    case APPLY_TO_TRIP:
      return {
        ...state,
        isFetching: true,
        trip: {
          ...state.trip,
          passengers: action.passengers,
        },
      };
    case CANCEL_APPLY_TO_TRIP:
      return {
        ...state,
        isFetching: true,
        trip: {
          ...state.trip,
          passengers: action.passengers,
        },
      };
    case UPDATED_TRIP:
      return {
        ...state,
        trip: {
          ...state.trip,
          status: action.status,
        },
        isFetching: false,
        notification: true,
        message: action.message,
      };
    case DONE_TRIP_UPDATE:
      return {
        ...state,
        isFetching: false,
        notification: true,
        message: action.message,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: false,
      };
    case UPDATE_REVIEW:
      return {
        ...state,
        trip: {
          reviews: action.reviews
        }
      };
    default:
      return state;
  }
}
