import Storage from './storage';

export function setSessionStorage(user) {
  try {
    return Storage.setLocalStorage('user', user);
  } catch (err) {
    return err;
  }
}

export function getSessionStorage() {
  try {
    return {
      user: Storage.getLocalStorage('user'),
    };
  } catch (err) {
    return err;
  }
}

export function clearSessionStorage() {
  try {
    Storage.removeLocalStorage('user');
    return true;
  } catch (err) {
    return err;
  }
}
