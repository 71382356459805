import User from '../services/User';
import Trip from '../services/Trip';
import Coupon from '../services/Coupon';
import { tripStatus } from '../helpers/constants';

export const GET_TRIP_INFORMATION = 'GET_TRIP_INFORMATION';
export const DELIVERED_TRIP_INFORMATION = 'DELIVERED_TRIP_INFORMATION';
export const APPLY_TO_TRIP = 'APPLY_TO_TRIP';
export const CANCEL_APPLY_TO_TRIP = 'CANCEL_APPLY_TO_TRIP';
export const DONE_TRIP_UPDATE = 'DONE_TRIP_UPDATE';
export const UPDATED_TRIP = 'UPDATED_TRIP';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const UPDATE_PASSENGERS ='UPDATE_PASSENGERS';

export function getTrip() {
  return {
    type: GET_TRIP_INFORMATION,
  };
}

export function deliveredTrip(information) {
  const { user, trip } = information;
  return {
    type: DELIVERED_TRIP_INFORMATION,
    user,
    trip,
  };
}

export function apply(passengers) {
  return {
    type: APPLY_TO_TRIP,
    passengers,
  };
}

export function cancel(passengers) {
  return {
    type: CANCEL_APPLY_TO_TRIP,
    passengers,
  };
}

export function update(status, message) {
  return {
    type: UPDATED_TRIP,
    status,
    message,
  };
}

export function review(reviews){
  return {
    type: UPDATE_REVIEW,
    reviews,
  };
}

export function done(message) {
  return {
    type: DONE_TRIP_UPDATE,
    message,
  };
}

export function passengers(trip) {
  return {
    type: UPDATE_PASSENGERS,
    trip,
  };
}

export function getTripInformation(id) {
  return (dispatch) => {
    dispatch(getTrip());

    let state = {};

    Trip.getInformation(id).then((result) => {
      state = {
        user: {
          uid: result.driver.userId,
        },
        trip: result,
      };
      const { user } = state;
      User.getOtherUserInformation(user.uid).then((result) => {
        state.user = result;
        dispatch(deliveredTrip(state));
      });
    });
  };
}

export function applyToTrip(id, passengers) {
  return (dispatch) => {
    dispatch(apply(passengers));
    Trip.apply(id).then(() => {
      dispatch(done('Reservación enviada exitosamente. Espere el contacto del conductor.'));
    });
  };
}

export function cancelTrip(id, passengers) {
  return (dispatch) => {
    dispatch(cancel(passengers));
    Trip.cancel(id).then(() => {
      dispatch(done('Reservación cancelada. Se le notificó al conductor.'));
    });
  };
}

export function updateTrip(id, status, message) {
  return (dispatch) => {
    const user = User.getSession();

    if(status === tripStatus.FINISHED && !user.hasCoupon) {
      const tripUpdate = Trip.update(id, status, 'status'); // Update trip as finished
      const userUpdate = User.update({...user, hasCoupon: true }); // User has a coupon now
      const couponCreate = Coupon.create(user.uid); // Create coupon for the user
      Promise.all([tripUpdate, userUpdate, couponCreate]).then(response => {
        User.setSession({...user, hasCoupon: true });
        dispatch(update(status, message));
      });
    } else {
      Trip.update(id, status, 'status').then(() => {
        dispatch(update(status, message));
      });
    }
  };
}

 export function updateReview(trip, reviews, ) {
  return (dispatch) => {
    Trip.update(trip, reviews, 'reviews').then(()=>{
      dispatch(review(reviews));
    });
  } ;
 }

 export function updatePassengerReview(id, trip) {
  return (dispatch) => {
    Trip.updateTrip(id, trip).then(()=>{
      dispatch(passengers(trip));
    });
  };
 }