import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import Card from '@material-ui/core/Card/Card';
import Paper from '@material-ui/core/Paper/Paper';
import { Link } from 'react-router-dom';

const Terms = () => {
  const url = (<a href="https://www.jyba.app">https://www.jyba.app</a>);

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center">
        <Card id="legal">
          <Paper className="text">
            <strong><Link to="/">Volver a página de inicio</Link></strong>
            <h1>Términos y Condiciones</h1>
            <i className="update">Última actualización: 4 de Diciembre de 2019</i>
            <p>El presente documento establece la forma adecuada en la que habrán de utilizarse las plataformas de JYBA, así como los términos a los que se sujetarán tanto el sitio web como la Aplicación (App), ante cualquier persona que hago uso de este, ya sea Usuario o Tercero. Cualquier persona que desee acceder y/o usar la Aplicación y sus Servicios podrán hacerlo sujetándose a los términos, junto con todas las demás políticas y principios que rigen a JYBA y que son incorporados al presente por referencia.</p>
            <p>JYBA opera y provee servicios especializados para el transporte terrestre, mismos que serán descritos en el cuerpo de los presentes términos, a través de medios electrónicos en el sitio www.jyba.app. Pone a disposición de los Usuarios una plataforma de contacto para compartir gastos de transportación en Vehículos privados.</p>

            <h2>INTRODUCCIÓN, OBJETO, SERVICIOS Y ACCESO</h2>
            <p>El sitio web de JYBA tiene la siguiente dirección: {url}, ésta te redireccionará a la App con la que podrás contar en tus aparatos electrónicos con acceso a Internet a través de un Navegador.</p>
            <p>A través de la App, JYBA facilita a sus Usuarios el acceso y la utilización de diversos servicios y contenidos, entre ellos, el servicio de compartición de gastos de traslado en vehículos privados sin fines lucrativos (en adelante, los “Servicios”) puestos a disposición por JYBA o por terceros. Los presentes términos tienen por objeto establecer y regular el acceso general a la App, así como a los distintos contenidos y servicios prestados por JYBA o por terceros desde y/o a través de éste.</p>
            <p>Cualquier persona que tenga acceso a la dirección anterior y de “clic” sobre el mismo, se considerará Usuario de esta y se diferenciará al Usuario que simplemente navegue por el sitio web, al Usuario que efectivamente tenga una cuenta validada con JYBA. </p>
            <p>Ambos tipos de Usuarios están sujetos a los términos que aquí se indiquen y al navegar sobre la dirección de JYBA se presumirá que entienden y aceptan lo que aquí se inserte y convenga. </p>
            <p>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIOS Y VINCULANTES, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS. EL USO DEL SITIO Y LA ACEPTACIÓN A ESTOS TÉRMINOS TENDRÁ LA MISMA FUERZA LEGAL QUE UNA FIRMA AUTÓGRAFA Y PODRÁ SER ELEGIBLE COMO PRUEBA JUDICIAL EN CASO DE CONTROVERSIA.</p>

            <h2>CONTENIDO Y USO</h2>
            <p>JYBA te ofrece una navegación ágil, visual y sencilla. El Usuario que navegue por el sitio web y/o aplicación (App) sin tener una cuenta validada, podrá visualizar el concepto esencial de JYBA, así como lo que se ofrece y el contacto de este, otorgándole la posibilidad al Usuario de que pueda registrarse y crear una cuenta validada, ya sea de manera personalizada o con cuenta Google y/o Facebook.</p>
            <p>Con carácter general el mero acceso a la App podrá ser libre. No obstante, JYBA condiciona el acceso a determinadas áreas de la App y la utilización de todos sus Servicios al previo registro del Usuario. La condición de Usuario de JYBA requiere mayoría de edad legal. El Usuario manifiesta y declara - en todo caso -, contar con al menos 18 años de edad. JYBA se reserva el derecho de negar el alta en la App. Esta negativa no implicará derecho a indemnización alguna.</p>
            <p>Al aceptar los términos aquí presentes, el Usuario acuerda y manifiesta su consentimiento sobre el hecho que JYBA pueda recopilar copias de identificaciones o documentos de identidad que le pertenezcan, incluyendo, pero no limitados a licencia de conducir y credencial de elector IFE o INE, con el propósito de validar la información contenida en dichas identificaciones o documentos de identidad.</p>
            <p>El Usuario con cuenta validada por JYBA tendrá acceso a otras pantallas del sitio web en las cuales se otorgará una serie de opciones para personalizar tu perfil y conocer mejor a las personas con las que podrías llegar a viajar. Así mismo, JYBA ofrece una sección de valoración del conductor, en una escala del 1 a 5 estrellas que podrás utilizar de la manera más profesional y honesta, para que gente como tú pueda evaluar sus opciones y ofrezca a JYBA una retroalimentación de las personas que hacen uso de este.</p>
            <p>El Usuario podrá hacer uso del contenido de la página de la manera más prudente posible y de acuerdo con lo que se indique en este documento, así como en las leyes correspondientes. </p>
            <p>Estará estrictamente prohibido, en la sección de fotografía, subir imágenes o fotos ofensivas, imprudentes y/o violentas, la persona que entre dentro de este supuesto, será dada de baja por JYBA automáticamente, en cumplimiento con las presentes políticas. Lo mismo aplicará para la sección del chat y los comentarios. </p>
            <p>El Usuario acepta y consiente que con navegar y utilizar la App estará aceptando tácitamente los presentes términos y manifiesta tácitamente un consentimiento de la voluntad de los términos. La utilización de ciertos servicios ofrecidos a los Usuarios a través de la App se encuentra sometida a condiciones particulares (en adelante, las “Condiciones Particulares”) que, según los casos, podrán sustituir, completar y/o modificar los presentes términos y condiciones generales.</p>

            <h2>SERVICIOS DE JYBA</h2>
            <p>JYBA pone a disposición de los Usuarios por medio de la App una plataforma digital para que éstos puedan contactarse entre sí para compartir gastos de transporte privado, respecto a los Trayectos que van a recorrer en un vehículo privado a través de vías generales de comunicación.</p>
            <p>JYBA prohíbe estrictamente hacer negocios haciendo uso de vehículos privados dentro de la plataforma, Aplicación, o durante los Trayectos, es decir un Conductor que publica un viaje no puede sacar un beneficio económico más allá de compartir los gastos en caso de utilizar un vehículo privado.</p>
            <p>JYBA cumple con el filtro de solicitar la Licencia de Conducir a los conductores para contar con una forma más certera de comprobar si la persona está en posibilidades y condiciones de manejar. Además, el Usuario acepta que JYBA pueda solicitar documentos adicionales, incluyendo, pero no limitado, a póliza de seguro de responsabilidad civil, póliza de seguro de viajero o dictamen de condiciones físico-mecánicas. Por lo mismo, JYBA no se hace responsable en caso de que un conductor esté imposibilitado para manejar y al mismo tiempo realice un viaje bajo condiciones ilegales, debido a que presentare alguna Licencia o documento falso o con datos inexistentes, o que al momento del viaje se presentaren circunstancias que no pueden estar bajo control de JYBA. Todo Conductor que utilice un Vehículo para realizar un Trayecto, deberá contar con un Seguro vigente que cubra como mínimo daños a terceros.</p>
            {/* <p>En caso de que el Conductor tenga un vehículo mayor de 9 pasajeros, tendrá la obligación de obtener los permisos necesarios estipulados en la Ley para prestar los servicios correspondientes de autotransporte. En caso de que el Conductor esté facultado para realizar operación de autotransporte de pasajeros con los permisos necesarios, deberá hacer de conocimiento a JYBA para poder solicitar documentación adicional por parte del Conductor. </p> */}
            <p>La concertación de acuerdos entre Usuarios de la App para la utilización compartida de vehículos (autos) es el resultado de acuerdos libres previos celebrados entre usuarios mayores de edad (Conductores y Pasajeros), que actúan bajo su plena y sola responsabilidad. Los Usuarios, al utilizar la App aceptan, reconocen y se comprometen a no iniciar acción legal en contra de JYBA, sus subsidiarias, accionistas, empleados, o cualquier persona física o moral que pertenezca al grupo por cualquier pérdida parcial o total de bienes materiales. Los Usuarios reconocen que JYBA se excluye expresamente cualquier tipo de responsabilidad por cualesquiera consecuencias derivadas de los acuerdos celebrados entre los Usuarios, y reconocen que JYBA es solo una plataforma que ofrece a los Usuarios un servicio de contacto, mas no tiene responsabilidad alguna sobre el desarrollo, ejecución y perfección de dicho servicio.</p>

            <h2>MODIFICACIONES POR PARTE DE JYBA</h2>
            <p>El Usuario queda expresamente informado y acepta que estos términos podrán experimentar variaciones en el futuro a libre criterio de JYBA y los cuales serán notificados por correo electrónico y/o a través del Sitio Web. Cada vez que JYBA realice un cambio a sus términos, el Usuario deberá aceptarlos nuevamente.</p>
            <p>JYBA tendrá la prerrogativa de hacerle modificaciones a la imagen y al contenido del sitio web y su aplicación (App), las veces que así lo requiera o guste sin tener que dar previo aviso a sus Usuarios o Terceras personas; lo anterior, no implica que los usuarios no puedan enviar recomendaciones u opiniones al respecto, éstas se recibirán y se analizarán por las personas correspondientes y en medida de lo indispensable, se dará respuesta a dichas solicitudes y/u observaciones. Es importante señalar que lo anterior, no significa que lo que se resuelva o conteste al respecto, sea vinculativo de alguna manera, sino que será meramente informativo y consultivo. </p>

            <h2>HARDWARE Y SOFTWARE DEL USUARIO</h2>
            <p>JYBA no será responsable por los problemas técnicos o desperfectos que se llegaren a presentar en el uso del sitio web y/o aplicación (App) con lo relativo a la capacidad y velocidad del Internet de cada Usuario, la carga de la página, y cualquier otra cuestión técnica particular. Por otro lado, cualquier desperfecto que el sitio web y/o aplicación (App) presente y que efectivamente sea competencia y responsabilidad de JYBA, se presentará aviso de mantenimiento sobre la misma página, de forma contraria, JYBA se deslindará de responsabilidades no aplicables al mismo.</p>
            <p>Con carácter general los diferentes servicios estarán disponibles ininterrumpidamente en Internet. Sin embargo, el Usuario queda informado de que JYBA NO garantiza en modo alguno esta continuidad debido a la naturaleza del medio a través del que se prestan. El Usuario entiende y acepta los aspectos que implica la prestación de servicios a través de Internet dado el carácter abierto, descentralizado y global de la red.</p>
            <p>Queda prohibido enviar, introducir o ejecutar cualquier información, programa o secuencia que contenga virus, caballos de Troya, gusanos, bombas de tiempo, huevos de pascua u otras rutinas de programación informática que puedan dañar, interferir negativamente, interceptar o expropiar cualquier sistema, dato o información personal; inyectar o introducir un enlace directo o indirectamente a cualquier otro sitio web salvo que sea explícitamente solicitado.</p>
            <p>En general, Los Usuarios, no pueden utilizar ningún tipo de aparato, software o rutina, existente o por inventar, que interfiera o entorpezca con el funcionamiento adecuado de la App y se obligan a no utilizar técnicas de ingeniería inversa, des compilación, o des ensamblaje, ni a modificar, traducir la App, toda vez que el mismo goza de la protección que las leyes y tratados de propiedad industrial e intelectual establecen.</p>

            <h2>OBLIGACIONES DEL USUARIO</h2>
            <p>JYBA maneja con datos personales y sensibles ya que se necesitarán para poder cumplir con nuestros objetivos, principalmente el de brindarle mayor seguridad al Usuario al viajar, estos datos serán resguardados y se pueden revisar las Políticas de Privacidad para más información al respecto. En el mismo orden de ideas, JYBA necesita que el nombre que el Usuario establezca como predeterminado, concuerde con el que aparece en la identificación o licencia de conducir, ya que esto ayudará a mantener el objetivo de JYBA, que a final de cuentas es beneficiar al Usuario brindando mayor seguridad.</p>
            <p>El Usuario tendrá que ser honesto al vaciar la información personal y de viaje en el sitio web y la aplicación (App), ya que las demás personas buscarán dentro de los Usuarios validados un acompañante con sus mismos intereses y preferencias. El Usuario deberá garantizar la veracidad y exactitud de los datos de registro introducidos en la App de JYBA y llevar a cabo cuantas actualizaciones crea necesarias. En todo caso el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a JYBA o a terceros por la información que facilite, comprometiéndose expresamente a mantener indemnes a JYBA y/o a cualesquiera terceros por los daños y perjuicios que se pudieran derivar de la inexactitud o falta de veracidad de dichos datos.</p>
            <p>Cabe resaltar que el mismo sitio web y la aplicación (App), te darán la opción de elegir lo que se ve públicamente, por lo que, lo que el Usuario decida hacer público, aún cuando se trate de datos personales y sensibles, quedará bajo su más estricta y única responsabilidad.</p>
            <p>Queda estrictamente prohibido hacer un mal uso del sitio web y/o la aplicación (App) de JYBA, así como buscar apropiarse de la información aquí contenida. Todos los derechos de propiedad intelectual pertenecen a JYBA y no a los Usuarios, a menos que se trate de fotografías y/o medios visuales subidos por el mismo Usuario.</p>
            <p>Además, el Usuario deberá abstenerse de realizar prácticas o usos de los servicios con fines ilícitos, fraudulentos, lesivos de derechos o intereses de JYBA o de terceros, infractores de las normas contenidas en los presentes términos o en las Condiciones Particulares que en su caso regulen cualquiera de los servicios prestados desde o a través de la App.</p>
            <p>El Usuario se abstendrá de mantener varias cuentas de usuario para una única identidad y abstenerse igualmente de suplantar a ningún otro usuario en la App. Esto es: los usuarios se comprometen a mantener una única cuenta de usuario en la App y a no actuar a través de identidades de otros o identidades simuladas.</p>

            <h2>NORMAS DE BUENA CONDUCTA</h2>
            <p>Como parte de las normas de buena conducta, el Usuario deberá comportarse de forma razonable y educada en cualesquiera acuerdos de compartición de autos y/o gastos asociados para traslados por carretera, comprometiéndose expresamente el usuario a observar en todo momento los siguientes puntos.</p>
            <p>El Usuario acepta que está autorizado a utilizar esta App únicamente para los fines descritos en estos términos. El Usuario acepta que es completamente responsable de sus actividades durante el uso de esta App y que se hará cargo de todos los riesgos con respecto al uso de esta App. El Usuario se compromete a no utilizar esta App para participar en cualquier conducta prohibida:</p>
            <ol type="I">
              <li>Queda estrictamente prohibido transportar cualquier tipo de estupefaciente, enervante o cualquier otra sustancia natural o procesada que esté prohibida por la Ley. De igual forma, queda estrictamente prohibido el traslado o transporte de armas blancas y/o armas de fuego de conformidad a las Leyes Federales.</li>
              <li>Se prohíbe la difusión de información falsa o personificar o hacerse pasar por una persona distinta a la cual efectúo el registro de Usuario.</li>
              <li>Se prohíbe cualquier conducta que incite a la violencia física, mental o emocional o a la comisión de algún delito o acto ilícito.</li>
              <li>Los Usuarios, cooperarán con JYBA para denunciar a cualquier persona que incumpla de alguna manera con los presentes términos.  JYBA se reserva el derecho para denunciar ante las autoridades competentes cualquier conducta que ponga en riesgo la seguridad, salud y/o la vida de cualquier persona, Usuario o No Usuario.</li>
            </ol>

            <h2>DESACTIVACIÓN DE LA CUENTA</h2>
            <p>Tú como Usuario podrás desactivar tu cuenta en cualquier momento y por tus propios motivos. Al hacer esto, JYBA te solicitará alguna explicación breve del por qué tomarás dicha decisión, sin que esto implique alguna otra cosa mas que retroalimentación para JYBA. </p>
            <p>Una vez desactivada tu cuenta, los datos personales y/o sensibles que hayas compartido con JYBA permanecerán en nuestra base de datos por un término de 3 meses para en caso de que autoridad competente requiera dicha información. Después de pasado este termino, tus datos quedarán completamente eliminados y nosotros como JYBA, deslindados de responsabilidad ante ellos por el mal uso de estos. </p>

            <h2>EXCLUSIÓN DE GARANTÍAS Y LEY APLICABLE</h2>
            <p>El acceso a la App no implica ningún tipo de garantía, que expresamente se rehúsa por JYBA, respecto a la calidad, veracidad, exactitud, licitud, actualidad o vigencia, así como la utilidad o adecuación a finalidad alguna de quien acceda a ellas. Tanto el acceso a la App como el uso que pueda hacerse de la información y contenidos incluidos en el mismo o que sea accesible desde el mismo, se efectúa bajo la exclusiva responsabilidad del Usuario.</p>
            <p>JYBA no responderá en ningún caso y en ninguna medida, ni por daños directos ni indirectos, ni por daño emergente ni por lucro cesante, por los eventuales perjuicios derivados del uso de la información y contenidos de la App o accesibles desde o a través de este.</p>
            <p>Los términos de uso aquí planteados son un acuerdo válido entre JYBA y el Usuario. Por lo anterior, es que, tanto JYBA como el Usuario se comprometen a intentar resolver de manera amistosa cualquier desacuerdo que pudiera surgir en el desarrollo de las relaciones mercantiles que les son propias. En caso de cualquier disputa o conflicto entre las partes, la jurisdicción corresponderá a los Tribunales Competentes de Chihuahua, Chihuahua, México, a menos que la Ley correspondiente indique distinto. </p>

            <strong><Link to="/">Volver a página de inicio</Link></strong>
          </Paper>
        </Card>
      </Grid>
    </Fragment>
  );
};

export default Terms;
