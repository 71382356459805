import {
  USER_REGISTER,
  USER_LOGGING_IN,
  USER_FAILED_LOGIN,
  USER_LOGOUT,
  USER_LOGIN,
  STOP_FETCHING,
  UPDATED_PICTURE,
  USER_PHONE_IN_USE,
  SEND_FORGOT_PASSWORD,
  SENT_PASSWORD_EMAIL,
} from "../actions";
import User from '../services/User';

const session = User.getSession();

export default function login(state = {
  isLoading: false,
  isLoggedIn: !!(session), // Check if is already logged in
  user: session,
  error: '',
  phoneError: { error: false, message: ''},
}, action) {
  switch (action.type) {
    case USER_REGISTER:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
      };
    case USER_LOGGING_IN:
      return {
        ...state,
        isLoading: true,
      };
    case USER_FAILED_LOGIN:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        error: action.message,
      };
    case USER_LOGIN:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
        isLoading: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        user: User.getSession(),
        isLoading: false,
        isLoggedIn: false,
      };
    case STOP_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATED_PICTURE:
      return {
        ...state,
        user: action.user,
      };
    case USER_PHONE_IN_USE:
      return {
        ...state,
        phoneError: {
          error: true,
          message: 'Este teléfono ya esta en uso.'
        }
      };
    case SEND_FORGOT_PASSWORD:
      return {
        ...state,
      };
    case SENT_PASSWORD_EMAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
