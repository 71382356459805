import moment from 'moment';
import User from '../services/User';
import firebase, {
  auth,
  googleProvider as google,
  facebookProvider as facebook,
} from '../helpers/firebase';
import { validateUser } from '../helpers/functions';

export const USER_REGISTER = 'USER_REGISTER';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_FAILED_LOGIN = 'USER_FAILED_LOGIN';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const STOP_FETCHING = 'STOP_FETCHING';
export const SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD';
export const SENT_PASSWORD_EMAIL = 'SENT_PASSWORD_EMAIL';
export const USER_PHONE_IN_USE = 'USER_PHONE_IN_USE';

export function userRegister(user) {
  return {
    type: USER_REGISTER,
    user,
  };
}

export function userLogin(user) {
  return {
    type: USER_LOGIN,
    user,
  };
}

export function userLoggingIn() {
  return {
    type: USER_LOGGING_IN,
  };
}

export function userFailedLogin(message) {
  return {
    type: USER_FAILED_LOGIN,
    message,
  };
}

export function userLogout() {
  return {
    type: USER_LOGOUT,
    user: {},
  };
}

export function stopIsFetching() {
  return {
    type: STOP_FETCHING,
  }
}

export function userPhoneInUse(){
  return {
    type: USER_PHONE_IN_USE,
  }
}

export function sendPasswordRecovery() {
  return {
    type: SEND_FORGOT_PASSWORD,
  }
}

export function recoverySent() {
  return {
    type: SENT_PASSWORD_EMAIL,
  }
}

export function register(user) {
  return (dispatch) => {
    let credential = firebase.auth.EmailAuthProvider.credential(user.email.trim(), user.password);
    return auth.currentUser.linkAndRetrieveDataWithCredential(credential)
      .then((result) => {
        if (result) {
          const { name, lastName, phone } = user;
          const full = {
            ...result,
            name,
            last: lastName,
            user: {
              ...result.user,
              phoneNumber: phone,
              phoneVerified: true,
            },
          };

          const session = User.makeUserSession(full);
          User.createUserRegistry(session);

          auth.onAuthStateChanged((newUser) => {
            newUser.sendEmailVerification();
          });

          User.setSession(session);
          const updateUser = User.getSession(session);
          updateUser.isVerified = 1;
          User.update(updateUser);
          User.setSession(updateUser);
          dispatch(userRegister(session));
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        switch (errorCode) {
          case 'auth/weak-password':
            alert('The password is too weak.'); // eslint-disable-line no-alert
            break;
          case 'auth/email-already-in-use':
            alert('Mail already in use.'); // eslint-disable-line no-alert
            break;
          case 'auth/provider-already-linked':
            dispatch(userPhoneInUse());
            break;
          default:
            alert(errorMessage); // eslint-disable-line no-alert
        }
      });
  };
}

export function login(user) {
  const { email, password } = user;

  return (dispatch) => {
    dispatch(userLoggingIn());
    return auth.signInWithEmailAndPassword(email, password).then(async (result) => {
      if (result) {
        const info = await User.getInformation(result);
        const userVerify = info;
        validateUser(userVerify);
        dispatch(userLogin(userVerify));
      } else {
        dispatch(userFailedLogin());
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/invalid-email') {
        alert('Invalid Email.'); // eslint-disable-line no-alert
      } else if (errorCode === 'auth/wrong-password') {
        alert('The password is wrong.'); // eslint-disable-line no-alert
      } else {
        alert(errorMessage); // eslint-disable-line no-alert
      }
    });
  };
}

export function loginWithGoogle() {
  return (dispatch) => {
    dispatch(userLoggingIn());
    return auth.signInWithPopup(google) // TODO: Change this to signInWithRedirect in the future
      .then(async (result) => {
        if (result) {
          let user = await User.getInformation(result);
          const { metadata } = result.user;

          const lastLogin = moment(metadata.lastSignInTime);
          const createDate = moment(metadata.creationTime);

          if (lastLogin.isSame(createDate)) {
            const session = User.makeUserSession(result); // To avoid empty sessions
            User.createUserRegistry(session);
            user = { ...session };
          } else {
            User.createUserRegistry(user);
          }

          // Users logging in with Google automatically have their email validated
          validateUser(user);
          dispatch(userLogin(user));
        } else {
          dispatch(userFailedLogin('error'));
        }
      }).catch((error) => { // eslint-disable-line
        // Handle Errors here.
        // The firebase.auth.AuthCredential type that was used.
        // The email of the user's account used.
        const {
          code,
          message,
          email,
          credential,
        } = error;

        // Account exists with different credential. To recover both accounts
        // have to be linked but the user must prove ownership of the original
        // account.
        if (code === 'auth/account-exists-with-different-credential') {
          // Lookup existing account’s provider ID.
          return auth.fetchProvidersForEmail(email)
            .then((providers) => { // eslint-disable-line
              if (providers.indexOf('email') !== -1) {
                // Password account already exists with the same email.
                // Ask user to provide password associated with that account.
                const password = window.prompt(`Please provide the password for  ${email}`); // eslint-disable-line no-alert
                return auth.signInWithEmailAndPassword(email, password);
              } else if (providers.indexOf('facebook.com') !== -1) {
                // Sign in user to Google with same account.
                google.setCustomParameters({ login_hint: email });
                return auth.signInWithPopup(google).then((response) => {
                  console.log('response.user', response.user); // eslint-disable-line no-console
                  return response.user;
                });
              }
            })
            .then((user) => {
              // Existing email/password or Google user signed in.
              // Link Facebook OAuth credential to existing account.
              return user.linkAndRetrieveDataWithCredential(credential);
            });
        }
        dispatch(userFailedLogin(message));
      });
  };
}

export function loginWithFacebook() {
  return (dispatch) => {
    dispatch(userLoggingIn());

    // Add user extra scope to gather from Facebook
    facebook.addScope('email');
    // facebook.addScope('user_birthday');
    // facebook.addScope('user_gender');
    // facebook.addScope('user_hometown');

    return auth.signInWithPopup(facebook) // TODO: Change this to signInWithRedirect in the future
      .then(async (result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const token = result.credential.accessToken;

        if (result) {
          // Facebook users and normal users from this application are different
          let facebookUser = await result.user;
          let jybaUser = null;

          const photoFileUrl = `${facebookUser.photoURL}?type=large`;

          const { metadata } = result.user;

          const lastLogin = moment(metadata.lastSignInTime);
          const createDate = moment(metadata.creationTime);

          if (lastLogin.isSame(createDate)) {
            const session = User.makeUserSession(result); // To avoid empty sessions
            User.createUserRegistry(session);
            facebookUser = { ...session };
            jybaUser = await User.getInformation({ user: facebookUser });
            jybaUser.photo = photoFileUrl;
          } else {
            jybaUser = await User.getInformation({ user: facebookUser });
          }

          // Users logging in with Facebook automatically have their email validated
          validateUser(jybaUser);

          User.setSession(jybaUser);
          dispatch(userLogin(jybaUser));
        } else {
          dispatch(userFailedLogin('error'));
        }
      }).catch((error) => { // eslint-disable-line
        // The firebase.auth.AuthCredential type that was used.
        // The email of the user's account used.
        const {
          code,
          message,
          email,
          credential,
        } = error;

        // Account exists with different credential. To recover both accounts
        // have to be linked but the user must prove ownership of the original
        // account.
        if (code === 'auth/account-exists-with-different-credential') {
          // Lookup existing account’s provider ID.
          return auth.fetchProvidersForEmail(email)
            .then((providers) => { // eslint-disable-line
              if (providers.indexOf('email') !== -1) {
                // Password account already exists with the same email.
                // Ask user to provide password associated with that account.
                const password = window.prompt(`Please provide the password for  ${email}`); // eslint-disable-line no-alert
                return auth.signInWithEmailAndPassword(email, password);
              } else if (providers.indexOf('google.com') !== -1) {
                // Sign in user to Google with same account.
                google.setCustomParameters({ login_hint: email });
                return auth.signInWithPopup(google).then((result) => {
                  console.log('result.user', result.user); // eslint-disable-line no-console
                  return result.user;
                });
              }
            })
            .then((user) => {
              // Existing email/password or Google user signed in.
              // Link Facebook OAuth credential to existing account.
              return user.linkAndRetrieveDataWithCredential(credential);
            });
        }
        dispatch(userFailedLogin(message));
      });
  };
}

export function logout() {
  return (dispatch) => {
    auth.signOut().then(() => {
      User.clearSession();
      dispatch(userLogout());
    });
  };
}

export function stopPhoneIsFetching() {
  return (dispatch) => {
    dispatch(stopIsFetching());
  }
}

export function sendForgotPasswordEmail(email) {
  return (dispatch) => {
    dispatch(sendPasswordRecovery());

    auth.sendPasswordResetEmail(email.trim()).then(function() {
      // Email sent.
      console.log('Email sent');
      recoverySent();
    }).catch(function(error) {
      console.log('An error sending the email happened.', error);
    });
  }
}