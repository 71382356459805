import { connect } from 'react-redux';
import App from '../components/App';
import { logout } from '../actions';

function mapStateToProps(state) {
  return {
   isLoggedIn: state.login.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(logout());
    }
  }
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;