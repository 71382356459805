import firebase, { firestore } from '../helpers/firebase';
import User from './User';
import { tripStatus } from "../helpers/constants";

class Trip {

  static async getInformation(id) {
    const database = firestore.collection('trips').doc(id);
    return database.get().then((snapshot) => {
      return snapshot.data();
    });
  }

  static async getTripsAsDriver(id) {
    return firestore.collection('trips').where('driver.userId', '==', id).get();
  }

  static async getTripsAsPassenger(id) {
    const isPending = firestore.collection('trips').where(`passengers.${id}.id`, '==', id).where(`passengers.${id}.status`, '==', 0).get();
    const isAccepted = firestore.collection('trips').where(`passengers.${id}.id`, '==', id).where(`passengers.${id}.status`, '==', 1).get();

    const [isPendingSnapshot, isAcceptedSnapshot] = await Promise.all([
      isPending,
      isAccepted
    ]);

    const pendingArray = isPendingSnapshot.docs;
    const acceptedArray = isAcceptedSnapshot.docs;

    return pendingArray.concat(acceptedArray);
  }

  static async getAcceptedTrips(id) {
    return firestore.collection('trips').where(`passengers.${id}.id`, '==', id).where(`passengers.${id}.status`, '==', 1).get();
  }

  static async updatePassengerStatus(data, status) {
    const { trip, id } = data;
    const tripRef = firestore.collection('trips').doc(trip);
    return tripRef.update({[`passengers.${id}.status`]: status});
  }

  static apply(id) {
    const user = User.getSession();
    const { photo, name, phone, uid } = user;
    const item = {
      status: 0,
      photo,
      name: `${name.firstName} ${name.lastName}`,
      phone: `+${phone.code}${phone.number}`,
      review:'',
      id: uid,
    };
    const tripRef = firestore.collection('trips').doc(id);
    const passenger = `passengers.${uid}`;
    return tripRef.update( {[passenger]: item} );
  }

  static async cancel(id) {
    const user = User.getSession();
    const tripRef = firestore.collection('trips').doc(id);
    return tripRef.update({ [`passengers.${user.uid}`]: firebase.firestore.FieldValue.delete() });
  }

  static create(trip) {
    return firestore.collection('trips').add(trip);
  }

  static update(id, value, type = '') {
    if (type) {
      return firestore.collection('trips').doc(id).update({ [type]: value });
    } else {
      return firestore.collection('trips').doc(id).update(value);
    }
  }

  static async getTripsResults(origin, destination, date) {
    const _date = date;
    const before = _date.set({'hour': 23, 'minute': 59}).toDate();
    const after = _date.set({'hour': 0, 'minute': 0}).toDate();
    return firestore.collection('trips')
                        .where('origin', '==', origin)
                        .where('destination', '==', destination)
                        .where('status', '==', tripStatus.AVAILABLE)
                        .where('date', '>', after)
                        .where('date', '<', before)
                        .get();
  }

  static async getUserFinishedTrips(id) {
    const tripRef = firestore.collection('trips').where('userId', '==', id).where('status', '==', 2);
    return tripRef.get();
  }

}

export default Trip;
