import {
  SUBMITTED_ERROR,
  SUBMITTED_INFORMATION,
  SUBMITTING_INFORMATION,
  GET_INFORMATION,
  DELIVERED_INFORMATION,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SUBMITTING_DOCUMENTS,
  SUBMITTED_DOCUMENTS,
  SUBMISSION_FINISHED,
  SUBMITTED_PHONE,
  USER_LOGOUT,
  UPDATED_PICTURE,
  SUBMITTING_VEHICLE_PHOTO,
  SUBMITTED_VEHICLE_PHOTO,
  SUBMITTING_BANNER_PHOTO,
  SUBMITTED_BANNER_PHOTO,
  UPDATE_REVIEW,
  DELIVERED_COUPONS,
} from "../actions";

const information = {
  name: {
    firstName: '',
    lastName: '',
  },
  documents: {
    id: '',
    license: '',
  },
  isVerified: 0,
};

export default function admin(state = {
  isFetching: false,
  isFetchingVehicle: false,
  isFetchingBanner: false,
  information,
  notification: false,
  error: {},
  coupons: {},
}, action) {
  switch (action.type) {
    case SUBMITTING_INFORMATION:
      return {
        ...state,
        information: action.information,
        isFetching: true,
      };
    case SUBMITTED_INFORMATION:
      return {
        ...state,
        notification: true,
        result: action.result,
        isFetching: false,
      };
    case SUBMITTED_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case GET_INFORMATION:
      return {
        ...state,
        isFetching: true,
      };
    case DELIVERED_INFORMATION: // eslint-disable-line
      return {
        ...state,
        information: action.session,
        isFetching: false,
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: true,
      };case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: false,
      };
    case SUBMITTING_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case SUBMITTED_DOCUMENTS: // eslint-disable-line
      const documents = Object.assign({}, state.information.documents, action.documents);
      return {
        ...state,
        isFetching: false,
        information: {
          ...state.information,
          documents,
        },
      };
    case SUBMISSION_FINISHED:
      return {
        ...state,
        information: action.information,
        isFetching: false,
      };
    case SUBMITTED_PHONE:
      return {
        ...state,
        notification: true,
        isFetching: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        information,
      };
    case UPDATED_PICTURE:
      return {
        ...state,
        information: action.user,
        notification: true,
      };
    case SUBMITTING_VEHICLE_PHOTO:
      return {
        ...state,
        isFetchingVehicle: true,
      };
    case SUBMITTED_VEHICLE_PHOTO:
      return {
        ...state,
        isFetchingVehicle: false,
      };
    case SUBMITTING_BANNER_PHOTO:
      return {
        ...state,
        isFetchingBanner: true,
      };
    case SUBMITTED_BANNER_PHOTO:
      return {
        ...state,
        isFetchingBanner: false,
        notification: true,
      };
    case DELIVERED_COUPONS:
      console.log(action.coupons);
      return {
        ...state,
        isFetching: false,
        coupons: action.coupons,
      };
    case UPDATE_REVIEW:
      return {
        ...state,
        trip: {
          reviews: action.reviews
        }
      };
    default:
      return state;
  }
}
