import { auth, storage, firestore } from '../helpers/firebase';
import { getSessionStorage, setSessionStorage, clearSessionStorage } from '../helpers/session';

class User {
  static createUserRegistry(user) {
    const userId = user.uid;
    firestore.collection('users').doc(userId).set(user);
  }

  static signOut() {
    User.clearSession();
    auth.signOut();
  }

  static update(info) {
    const id = auth.currentUser.uid;
    const userRef = firestore.collection('users').doc(id);
    return userRef.update(info);
  }

  static async getInformation(session) {
    const { user } = session;
    const database = firestore.collection('users').doc(user.uid);

    return database.get().then((snapshot) => {
      return snapshot.data();
    });
  }

  static getOtherUserInformation(uid) {
    const database = firestore.collection('users').doc(uid);
    return database.get().then((snapshot) => {
      return snapshot.data();
    });
  }

  static checkExistingEmail(email) {
    return firestore.collection('users').where('email', '==', email).get().then(user  => {
      return user.empty;
    })
  }

  static getSession() {
    const session = getSessionStorage();
    const { user } = session;
    return user;
  }

  static setSession(info) {
    const session = getSessionStorage();
    let user = Object.assign({}, session.user);

    if (typeof info === 'object') {
      const keys = Object.keys(info);
      keys.forEach((key) => {
        if (Array.isArray(info[key])) {
          user[key] = info[key];
        } else if (typeof info[key] === 'object') { // Concatenate properties to don't lose data if object
          user[key] = Object.assign({}, user[key], info[key]);
        } else {
          user[key] = info[key];
        }
      });
    } else {
      user = { ...user, ...info };
    }
    setSessionStorage(user);
  }

  static clearSession() {
    clearSessionStorage();
  }

  static setDocument(document, file, picId = 0) {
    const { uid } = auth.currentUser;
    const storageRef = storage.ref();

    // Create paths for bucket = [id|license]\/^([0-9]|[a-z]|[A-Z]).^[jpg|png]
    const path = storageRef.child(`${document}/${uid}_${document}${(picId !== 0 ? picId : '')}.${file.extension}`);

    return path.put(file);
  }

  static updateProfile(profile) {
    const user = auth.currentUser;

    if (user) {
      return user.updateProfile(profile); // Return a promise
    }

    return false;
  }

  static makeUserSession(result) {
    const {
      user,
      additionalUserInfo,
    } = result;

    const {
      phoneNumber,
      phoneVerified,
      photoURL,
      email,
      emailVerified,
      uid,
    } = user;

    const { providerId, profile } = additionalUserInfo;

    let firstName = '';
    let lastName = '';
    let userGender = 'male';

    /* eslint-disable */
    if (providerId === 'google.com') {
      const { family_name, given_name } = profile;
      firstName = given_name;
      lastName = family_name;
    } else if(providerId === 'facebook.com') {
      const { first_name, last_name, gender } = profile;
      firstName = first_name;
      lastName = last_name;
      userGender = gender;
    } else {
      const { name, last } = result;
      firstName = name;
      lastName = last;
    }
    /* eslint-enable */

    // TODO: Solve bug here of not getting all the info saved on the database
    return {
      uid,
      name: {
        firstName,
        lastName,
      },
      phone: {
        code: 52,
        number: phoneNumber || 0,
      },
      phoneVerified: phoneVerified || false,
      photo: photoURL || '',
      banner: '',
      emailVerified,
      email,
      provider: providerId,
      isVerified: 0,
      documents: {
        id: '',
        license: '',
      },
      about: {
        special: 'no',
        reason: {
          hearing: false,
          movement: false,
          visual: false,
        },
      },
      birthday: {
        day: 1,
        month: 'Enero',
        year: 1995,
      },
      gender: userGender || 'male',
      city: '',
      country: 'México',
      rate: 0,
      notifications: {
        enabled: 'yes',
        reason: {
          messages: true,
          reserve: true,
          trips: true,
        },
      },
      preferences: {
        animals: 'no',
        eat: 'no',
        kids: 'no',
        music: 'no',
        smoke: 'no',
        talk: 'no',
      },
      vehicles: [],
      hasCoupon: false, //Coupon to redeem in the first trip
    };
  }
}

export default User;
