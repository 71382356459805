import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Card from '@material-ui/core/Card/Card';

const Privacy = () => {
  return (
    <Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center">
        <Card id="legal">
          <Paper className="text">
            <h1>Política de Privacidad</h1>
            <h3>¿Qué es Jyba?</h3>
            <p><strong>Jyba</strong> es una plataforma cuyo objetivo es el de
              facilitar el contacto entre personas que realizarán algún viaje de
              un destino a otro y que estos puedan compartir sus gastos. Esto
              atenderá tanto a lo particular como la contribución a aspectos
              socio-ambientales, tal como lo es la reducción de costos en el
              viaje y evitando gases de efecto invernadero por uso de múltiples
              vehículos. Además, <strong>Jyba</strong> tiene como objetivo
              principal el brindar mayor seguridad a las personas que utilicen
              el sitio web y/o aplicación (App) mediante la validación de la
              identidad de los usuarios, compartiendo la ubicación del trayecto
              mediante señal GPS y usando método de pago por tarjeta de crédito
              o débito.</p>
            <h3>¿Por qué entonces las Políticas de Privacidad
              de <strong>Jyba</strong>?</h3>
            <p>La Ley Federal de Protección de Datos Personales en Posesión de
              Particulares regula todo lo referente a la protección de tus datos
              cuando son requeridos por un
              particular. <strong>Jyba</strong> pone a disposición de todos sus
              usuarios la presente Política de Privacidad debido a que trata con
              datos personales y sensibles que nos proporcionas al utilizar
              nuestra plataforma, los cuales son requeridos única y
              exclusivamente para cumplir con el objetivo principal
              de <strong>Jyba</strong>: brindar seguridad y confianza a los
              usuarios de las personas con las que viajan, mediante un proceso
              de validación con tecnología avanzada de <strong>Jyba</strong> y
              los cuales no se esparcirán ante terceros a menos que exista una
              aceptación expresa de por medio.</p>
            <h3>Algunos términos que estarás leyendo en la Política de
              Privacidad de <strong>Jyba</strong>...</h3>
            <ol type="a">
              <li><strong>Datos Personales</strong>.- Cualquier tipo de
                información concerniente a una persona física identificada o
                identificable.
              </li>
              <li><strong>Datos Sensibles</strong>.- Datos personales que
                afectan a la esfera más íntima de su titular, o cuya utilización
                indebida pueda dar origen a discriminación o conlleve un riesgo
                grave para éste.
              </li>
              <li><strong>Titular</strong>.- Persona Física a quien corresponden
                los datos personales o sensibles
              </li>
              <li><strong>Tratamiento de Datos Personales</strong>.- Obtención,
                uso, divulgación o almacenamiento de datos personales, por
                cualquier medio.
              </li>
              <li><strong>Responsable</strong>.- Persona física o moral de
                carácter privado que decide sobre el tratamiento de datos
                personales.
              </li>
            </ol>
            <h3>Para cuando necesites contactar a Jyba</h3>
            <ul style={{ listStyleType: 'circle' }}>
              <li><strong>Domicilio</strong>: Calle Ahuehuete #1120 Colonia
                Magisterial Universidad, Chihuahua, Chihuahua. CP. 31200
              </li>
              <li><strong>Teléfono/ Celular de atención</strong>:
                +52(614)3667815
              </li>
              <li><strong>Correo Electrónico</strong>: contacto@jyba.app</li>
            </ul>
            <h3>Sobre tus datos personales</h3>
            <p>Al momento del registro en <strong>Jyba</strong>, además de la
              creación de una Contraseña, te solicitamos los siguientes datos
              personales de los cuales eres Titular:</p>
            <ul>
              <li>Nombre y Apellidos</li>
              <li>Correo Electrónico</li>
            </ul>
            <p>Una vez registrado, para completar la Validación del
              Usuario, <strong>Jyba</strong> requerirá de los siguientes datos
              personales de los cuales eres Titular:</p>
            <ul style={{ listStyleType: 'circle' }}>
              <li>Teléfonos y/o celulares</li>
              <li>Correo Electrónico</li>
              <li>Licencia de conducir en caso de ser conductor, o como medio de
                identificación
              </li>
              <li>IFE – INE, como medio de identificación</li>
            </ul>
            <p>Así mismo, en la creación de tu Perfil se te solicitarán los
              siguientes datos personales de los cuales eres Titular:</p>
            <ul style={{ listStyleType: 'circle' }}>
              <li>Nombre y Apellidos</li>
              <li>Sexo</li>
              <li>Correo Electrónico</li>
              <li>Teléfono/Celular</li>
              <li>Fecha de Nacimiento</li>
              <li>Lugar de Residencia</li>
              <li>Preferencias</li>
              <li>Datos Sensibles</li>
              <li>Datos de tu Vehículo (en caso de ser Conductor)</li>
            </ul>
            <p>En la sección de tu Perfil, los datos personales que serán
              visibles para los demás Usuarios de <strong>Jyba</strong>, para
              que estos puedan considerarte como conductor o acompañante en un
              viaje, serán los siguientes:</p>
            <ul style={{ listStyleType: 'circle' }}>
              <li>Nombre</li>
              <li>Viajes</li>
              <li>Preferencias</li>
              <li>Calificación</li>
              <li>Condición Especial</li>
            </ul>
            <p>Los datos como tu teléfono/celular, así como tu correo
              electrónico serán visibles una vez que se acepte un viaje en
              conjunto, por cuestiones de comunicación.</p>
            <p>Los datos mencionados te serán solicitados para
              que <strong>Jyba</strong> mantenga un control sobre las personas
              que se registran en el sitio web y/o la aplicación (App), a parte
              de los motivos principales que se exponen al principio de esta
              política. A través del correo
              electrónico, <strong>Jyba</strong> podrá enviarte correos con las
              actualizaciones del sitio web y/o aplicación (App), mensajes
              importantes, actualizaciones a la presente Política de Privacidad,
              entre otros.</p>
            <p><strong>Jyba</strong> está interesado en ofrecerte el mejor
              servicio posible de acuerdo a tus necesidades, y es por eso que te
              solicitará algunos datos sensibles relativos a tu estado médico
              general, considerando que la inclusión de personas con
              discapacidades o dificultad para desenvolverse de una manera
              rutinaria o cotidiana, es un derecho humano esencial.</p>
            <p>Los datos que te mencionamos anteriormente, serán resguardados
              bajo la más estricta confidencialidad y prudencia, nuestro
              colaboradores y equipo de trabajo, no tendrán acceso a estos
              datos, serán únicamente el Responsable y/o el Encargado, quienes
              en todo caso, se encargaran del tratamiento y el resguardo de tus
              datos personales y sensibles, y quienes observaran los principios
              de licitud, consentimiento, información, calidad, finalidad,
              lealtad, proporcionalidad y responsabilidad, de acuerdo a las
              Leyes correspondientes en la materia.</p>
            <p>El Responsable y quién decidirá sobre el tratamiento de tus datos
              personales y sensibles será <strong>Jyba</strong>, quién se regirá
              de acuerdo a lo que se establezca en todas las Leyes relacionadas
              a la materia.</p>
            <p>Los datos personales y/o sensibles que proporciones
              a <strong>Jyba</strong>, no serán divulgados ni utilizados para
              fines diferentes a lo establecido en la presente Política de
              Privacidad a menos que una autoridad competente lo requiera y
              dicha información sea indispensable para lo requerido. De la misma
              forma, deberán considerarse los supuestos que se establecen en el
              artículo 10 de la Ley Federal de Protección de los Datos
              Personales, y serán resguardados bajo los más estrictos sistemas
              de protección digital para evitar el mal uso o desvío de
              información.</p>
            <p><strong>Jyba</strong> te proporcionará la facilidad de que vacíes
              tu información de Pago para agilizar el proceso cuando decidas
              realizar tu viaje. Dicha información será completamente privada y
              particular, <strong>Jyba</strong> no tendrá ningún tipo de acceso
              a estos datos y se deslinda de cualquier responsabilidad
              relacionada a tu banco o tus datos de tarjeta, ya que la
              plataforma solo servirá como medio para hacer uso del mismo por el
              particular en sus viajes.</p>
            <p>A pesar de todo lo mencionado hasta ahora, cabe resaltar que el
              Internet es un área peligrosa en la que se pueden corromper
              sistemas y verse influenciado por terceros, por lo que todo el
              esfuerzo que se pondrá sobre la protección de tus datos estará
              sobre dicha línea, y conscientes de que hay un riesgo existente; a
              pesar de lo anterior, haremos todo lo que está en nuestras manos
              para brindarte esa seguridad que buscas en la protección de tus
              datos.</p>
            <h3>Derechos ARCO</h3>
            <p>Ahora, como Titular de tus datos personales, la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares te
              otorga los derechos de acceso, ratificación, cancelación y
              oposición sobre tus datos los cuales podrás ejercer enviando un
              correo electrónico al Responsable con los datos que te
              proporcionamos anteriormente, explicando el por qué de tu
              solicitud y tus datos básicos. De la misma forma, te garantizamos
              que contamos con mecanismos sencillos para poder atender a tu
              solicitud sin retrasos ni complicaciones innecesarias o que
              afecten a la eficaz atención de dicho ejercicio.</p>
            <p>A pesar de lo anterior, <strong>Jyba</strong> contará con la
              prerrogativa para no dar contestación a dichas solicitudes en los
              casos que se enumeran en el artículo 34 de la Ley Federal de
              Protección de Datos Personales en Posesión de Particulares.</p>
            <h3>Aceptación de los términos</h3>
            <p>Esta política de privacidad constituye un acuerdo válido entre el
              Titular de los Derechos Personales y el Responsable, es
              decir, <strong>Jyba</strong>. Si el Titular utiliza los servicios
              que <strong>Jyba</strong> ofrece, se presumirá que se han leído,
              entendido y aceptado los términos antes expuestos. En caso
              contrario se negará el acceso a la plataforma, otorgándote la
              apertura de enviar al correo electrónico,
              que <strong>Jyba</strong> te proporciona, tu motivo de desacuerdo.
              De esta manera, podremos analizar lo anterior más a detalle para
              ofrecerte un mejor servicio.</p>
            <h3>En caso de conflicto entre Jyba y el Usuario</h3>
            <p>Ante cualquier disputa o conflicto entre las partes, se resolverá
              ante los Tribunales Competentes de acuerdo a lo que se establece
              para el Procedimiento de Protección de Derechos en la Ley Federal
              de Protección de Datos Personales en Posesión de los Particulares
              y sus leyes análogas.</p>
            <strong><Link to="/">Volver a página de inicio</Link></strong>
          </Paper>
        </Card>
      </Grid>
    </Fragment>
  );
};

export default Privacy;
