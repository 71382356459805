import store from 'store';

class Storage {
  static bakeCookie(key) {
    return key ? `jyba_${key}` : null;
  }

  static setLocalStorage(key, value, exp) {
    if (key && value) {
      const cookie = this.bakeCookie(key);
      store.set(cookie, {
        value,
        exp: exp || null,
        time: new Date().getTime(),
      });
      return store.get(cookie);
    }
    return null;
  }

  static getLocalStorage(key) {
    const info = store.get(this.bakeCookie(key));

    if (!info || typeof info === 'undefined') return null;
    if (info.exp && new Date().getTime() - info.time > info.exp) return null;

    return info.value;
  }

  static removeLocalStorage(key) {
    const cookie = this.bakeCookie(key);
    store.remove(cookie);
  }
}

export default Storage;
