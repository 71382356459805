import { combineReducers } from 'redux';
import login from './login';
import admin from './admin';
import profile from './profile';
import trip from './trip';

const rootReducer = combineReducers({
  profile,
  login,
  admin,
  trip,
});

export default rootReducer;
