import React from 'react';
import thunkMiddleware from 'redux-thunk';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import rootReducer from './reducers';
import AppContainer from './containers/AppContainer';
import { isProduction, isDemo } from './helpers/functions';
import * as serviceWorker from './main-sw';
import './style/index.less';

let store = null;
const key = isDemo() ? 'UA-127305710-3' : 'UA-127305710-2';

// Register main service worker (using the one provided by CRA)
// The custom service worker is added on build
if( 'serviceWorker' in navigator) {
  serviceWorker.register();
}

/* eslint-disable */
if (isProduction()) {
  // Google Analytics
  ReactGA.initialize(key);
  ReactGA.pageview(window.location.pathname + window.location.search);

  LogRocket.init('6x1ft5/jyba'); // Logging service
  // TawkTo.init(); // Chat support service
  store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
} else {
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ }) : compose;
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
  store = createStore(rootReducer, enhancer);
}

render(<AppContainer store={store} />, document.getElementById('app'));
/* eslint-enable */
