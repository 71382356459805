import {
  GET_PROFILE_INFORMATION,
  DELIVERED_PROFILE_INFORMATION,
  GET_USER_TRIPS,
  DELIVERED_USER_TRIPS,
  USER_LOGOUT,
  UPDATED_PICTURE,
} from "../actions";

const information = {
  name: {
    firstName: '',
    lastName: '',
  },
  about: {
    special: 'no',
  },
  preferences: {
    eat: 'no',
    music: 'no',
    animals: 'no',
    smoke: 'no',
    talk: 'no',
  },
  isVerified: 0,
};

export default function profile(state = {
  isFetching: false,
  isFetchingTrips: false,
  information,
  trips: [],
}, action) {
  switch (action.type) {
    case GET_PROFILE_INFORMATION:
      return {
        ...state,
        isFetching: true,
      };
    case DELIVERED_PROFILE_INFORMATION:
      return {
        ...state,
        isFetching: false,
        information: action.session,
      };
    case GET_USER_TRIPS:
      return {
        ...state,
        isFetchingTrips: true,
      };
    case DELIVERED_USER_TRIPS:
      return {
        ...state,
        trips: action.trips,
        isFetchingTrips: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        information,
      };
    case UPDATED_PICTURE:
    return {
      ...state,
      information: action.user,
    };
    default:
      return state;
  }
}
